var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Удаление пользователя",
      "width": 500,
      "actions": _vm.actions
    }
  }, [_c('FormValidatable', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('UiCenteredText', [_vm._v(" Вы действительно хотите удалить пользователя " + _vm._s(_vm.email) + " из аккаунта? ")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };